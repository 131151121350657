"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addUploadSession = exports.updateUploadSession = void 0;
const date_fns_1 = require("date-fns");
const firebase_1 = require("./firebase");
const uploadSession = () => (0, firebase_1.getAppCompat)().firestore().collection('imageUploadSessions').withConverter({
    fromFirestore: (d) => {
        return d.data();
    },
    toFirestore: (d) => d
});
async function updateUploadSession(id, session) {
    return await uploadSession().doc(id).set(session, { merge: true });
}
exports.updateUploadSession = updateUploadSession;
async function addUploadSession(session) {
    console.log('Adding upload session: ', session);
    return await uploadSession().add({
        ...session,
        startTime: (0, date_fns_1.getUnixTime)(new Date())
    });
}
exports.addUploadSession = addUploadSession;
