"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.openJobAndBooking = void 0;
const accounts_models_1 = require("@ht-lib/accounts-models");
const firebase_1 = require("../firebase");
const activity_collection_1 = require("@ht-lib/activity-collection");
__exportStar(require("./accounts"), exports);
__exportStar(require("./Booking"), exports);
__exportStar(require("./bookingStudioJobs"), exports);
__exportStar(require("./index"), exports);
__exportStar(require("./job"), exports);
__exportStar(require("./photographers"), exports);
__exportStar(require("./types"), exports);
async function openJobAndBooking(bookingId, jobRef, userId) {
    return await new Promise((resolve, reject) => {
        let jobStatus;
        (0, firebase_1.getAppCompat)()
            .firestore()
            .runTransaction(async (transaction) => {
            const [bookingSnapshot, jobSnapshot] = await Promise.all([
                transaction.get((0, firebase_1.getAppCompat)().firestore().doc(`bookings/${bookingId}`)),
                transaction.get(jobRef)
            ]);
            const [booking, job] = [bookingSnapshot.data(), jobSnapshot.data()];
            if (booking == null || job == null)
                return;
            const bookingStatus = accounts_models_1.BookingStatus.CREATED;
            jobStatus = accounts_models_1.JobStatus.WAITING_FOR_PHOTOGRAPHERS;
            const work = [];
            if (job.status !== jobStatus) {
                work.push((0, activity_collection_1.addActivity)({
                    type: accounts_models_1.ActivityType.JOB,
                    userId: userId,
                    contextId: jobSnapshot.id,
                    message: `Status changing from ${job.status} to ${jobStatus}`,
                    labels: ['StatusChange']
                }));
            }
            if (booking.status !== bookingStatus) {
                work.push((0, activity_collection_1.addActivity)({
                    type: accounts_models_1.ActivityType.BOOKING,
                    userId: userId,
                    contextId: bookingSnapshot.id,
                    message: `Status changing from ${booking.status} to ${bookingStatus}`,
                    labels: ['StatusChange']
                }));
            }
            await Promise.all(work);
            transaction.update(jobRef, { status: jobStatus });
            transaction.update(bookingSnapshot.ref, { status: bookingStatus });
        })
            .then(() => resolve(jobStatus))
            .catch(e => {
            console.error('Error in openJobAndBooking', e);
            reject(e);
        });
    });
}
exports.openJobAndBooking = openJobAndBooking;
