"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageEntriesFromJob = void 0;
const accounts_models_1 = require("@ht-lib/accounts-models");
const job_1 = require("../models/job");
async function imageEntriesFromJob(studioJob, account, accountId, bookingId, uploaderId, jobId, job, labUploader) {
    console.log('JOB TYPE', job === null || job === void 0 ? void 0 : job.jobType);
    const aimsData = (job === null || job === void 0 ? void 0 : job.jobType) !== undefined ? await (0, job_1.getJobTypeFromCode)(job.jobType) : null;
    const images = studioJob.images.map(x => {
        var _a, _b, _c, _d, _e, _f, _g;
        const img = x.image;
        const entry = {
            pupilName: img.pupilName !== '' ? img.pupilName : null,
            accountId,
            bookingId,
            studioJobId: studioJob.studio.uid,
            uploaderId,
            nexusId: null,
            nudge: null,
            accountCode: (_c = (_a = account.code) !== null && _a !== void 0 ? _a : (_b = job === null || job === void 0 ? void 0 : job.account) === null || _b === void 0 ? void 0 : _b.code) !== null && _c !== void 0 ? _c : 'ERROR',
            jobCode: (_d = job === null || job === void 0 ? void 0 : job.aimsJobNumber) !== null && _d !== void 0 ? _d : null,
            jobId: jobId,
            jobSubTypeId: null,
            jobType: (_e = job === null || job === void 0 ? void 0 : job.jobType) !== null && _e !== void 0 ? _e : null,
            isPreferred: img.preferred,
            ratio: null,
            sizingCode: null,
            className: img.className,
            pupilId: img.dataLinkId !== '' ? img.dataLinkId : null,
            hash: null,
            orientation: img.orientation,
            longRef: img.longRef,
            photographerCode: img.photographerCode,
            ref: img.ref,
            status: img.flag ? accounts_models_1.ImageStatus.FLAGGED : img.accepted ? accounts_models_1.ImageStatus.NONE : accounts_models_1.ImageStatus.REJECTED,
            uploaded: false,
            studioRejected: !img.accepted,
            studioFlagged: img.flag || false,
            childProtectionIssue: false,
            takeDate: new Date(img.dateCreated).toString(),
            shotTypeId: (0, accounts_models_1.shotModeToShotType)(img.shotMode),
            colourCorrection: null,
            market: (_g = (_f = aimsData === null || aimsData === void 0 ? void 0 : aimsData.market) !== null && _f !== void 0 ? _f : job === null || job === void 0 ? void 0 : job.market) !== null && _g !== void 0 ? _g : 'S',
            imported: false
        };
        if (labUploader != null) {
            entry.labUploader = labUploader;
        }
        return entry;
    });
    return images;
}
exports.imageEntriesFromJob = imageEntriesFromJob;
