"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildThumbnailUrl = exports.getThumbnailObjectURL = void 0;
const lodash_1 = require("lodash");
const axios_1 = require("./axios");
const imagesNeedingThumbnail = new Set();
const callRegenerate = (0, lodash_1.debounce)(async () => {
    const payload = Array.from(imagesNeedingThumbnail.values());
    imagesNeedingThumbnail.clear();
    await (0, axios_1.getAxios)().post('/https-image/api/regenerate-thumbnails', payload);
}, 250);
function needsThumbsRegenerated(image) {
    return image.thumbnailLowName === undefined || image.thumbnailHighName === undefined;
}
function getThumbnailObjectURL(bucketName, image, low = true) {
    var _a;
    try {
        if (needsThumbsRegenerated(image)) {
            const highLow = {
                thumbnailLowName: image.thumbnailLowName,
                thumbnailHighName: image.thumbnailHighName
            };
            console.log(`Old thumbnail type detected, regenerating. ${JSON.stringify(highLow)}`);
            imagesNeedingThumbnail.add(image.longRef);
            (_a = callRegenerate()) === null || _a === void 0 ? void 0 : _a.catch(e => {
                console.error('Error calling regenerating thumbnails', e);
            });
        }
    }
    catch (e) {
        console.error('Error calling regenerateThumbnails API, trying to build URL as is', e);
    }
    return buildThumbnailUrl(bucketName, low ? image.thumbnailLowName : image.thumbnailHighName);
}
exports.getThumbnailObjectURL = getThumbnailObjectURL;
function buildThumbnailUrl(thumbnailBucket, name) {
    return `https://storage.googleapis.com/${thumbnailBucket}/${name}`;
}
exports.buildThumbnailUrl = buildThumbnailUrl;
