"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getJobTypeFromCode = exports.allJobTypes = exports.onlineJobTypes = exports.getAccountHolderJobs = exports.byBookingIdUploadSiteQuery = exports.searchJobs = exports.splitAimsJobNumber = exports.updatePhotographerJobStatusFromRef = exports.updatePhotographerJobStatus = exports.jobsWithStatus = exports.deleteJobPhotographer = exports.getEmptyJob = exports.getJob = exports.addLabUploaderToJob = exports.updateJobFromRef = exports.updateJob = exports.addJob = exports.jobsCollection = void 0;
/* eslint-disable accessor-pairs */
const accounts_models_1 = require("@ht-lib/accounts-models");
const date_fns_1 = require("date-fns");
const lodash_1 = require("lodash");
const firebase_1 = require("../firebase");
const jobDocumentConverter = {
    fromFirestore(snapshot) {
        const data = (snapshot.data ? snapshot.data() : snapshot);
        let takeDate = new Date();
        if (data.takeDate instanceof firebase_1.Timestamp) {
            takeDate = data.takeDate.toDate();
        }
        else if (typeof data.takeDate === 'string') {
            takeDate = (0, date_fns_1.parseISO)(data.takeDate);
        }
        return {
            ...data,
            takeDate
        };
    },
    toFirestore(modelObject) {
        return { ...modelObject };
    }
};
const jobsCollection = () => (0, firebase_1.getAppCompat)().firestore().collection('jobs').withConverter(jobDocumentConverter);
exports.jobsCollection = jobsCollection;
async function addJob(job, id) {
    const doc = (0, exports.jobsCollection)().doc(id);
    await doc.set(job);
    return doc;
}
exports.addJob = addJob;
async function updateJob(id, job) {
    return await (0, exports.jobsCollection)().doc(id).set(job, { merge: true });
}
exports.updateJob = updateJob;
async function updateJobFromRef(ref, job) {
    return await ref.set(job, { merge: true });
}
exports.updateJobFromRef = updateJobFromRef;
async function addLabUploaderToJob(ref, labUploaderId) {
    const update = {
        labUploaders: firebase_1.FieldValue.arrayUnion(labUploaderId)
    };
    await ref.update(update);
}
exports.addLabUploaderToJob = addLabUploaderToJob;
async function getJob(id) {
    return await (0, exports.jobsCollection)().doc(id).get();
}
exports.getJob = getJob;
async function getEmptyJob() {
    return await (0, exports.jobsCollection)().doc().get();
}
exports.getEmptyJob = getEmptyJob;
async function deleteJobPhotographer(jobId, photographerId) {
    await (0, exports.jobsCollection)().doc(jobId).update(`photographersMap.${photographerId}`, firebase_1.FieldValue.delete());
}
exports.deleteJobPhotographer = deleteJobPhotographer;
function jobsWithStatus(statuses) {
    return (0, exports.jobsCollection)()
        .where('status', 'in', statuses);
}
exports.jobsWithStatus = jobsWithStatus;
async function updatePhotographerJobStatus(jobId, userId, status) {
    return await updateJob(jobId, {
        photographersMap: {
            [userId]: {
                status
            }
        }
    });
}
exports.updatePhotographerJobStatus = updatePhotographerJobStatus;
async function updatePhotographerJobStatusFromRef(ref, userId, status) {
    return await updateJobFromRef(ref, {
        photographersMap: {
            [userId]: {
                status
            }
        }
    });
}
exports.updatePhotographerJobStatusFromRef = updatePhotographerJobStatusFromRef;
function splitAimsJobNumber(aimsJobNumber) {
    const result = { accountCode: '', jobCode: '', isComplete: false };
    aimsJobNumber = aimsJobNumber.replace(/\//g, '-');
    const [accountCode, jobCode] = aimsJobNumber.split('-');
    if (accountCode.trim().length === 6) {
        result.accountCode = accountCode.trim();
    }
    if (jobCode != null && jobCode.trim().length === 4) {
        result.jobCode = jobCode.trim();
    }
    result.isComplete = result.accountCode.length === 6 && result.jobCode.length === 4;
    return result;
}
exports.splitAimsJobNumber = splitAimsJobNumber;
function aimsJobNumberQuery(term) {
    const aimsJobNumber = splitAimsJobNumber(term);
    if (aimsJobNumber.isComplete) {
        return (0, exports.jobsCollection)()
            .where('account.code', '==', aimsJobNumber.accountCode)
            .where('aimsJobNumber', '==', aimsJobNumber.jobCode)
            .limit(10);
    }
    return null;
}
function searchJobs(term, cb) {
    var _a;
    let jobs = [];
    const onUpdate = (snap) => {
        jobs = (0, lodash_1.uniqWith)([...snap.docs, ...jobs], (a, b) => a.id === b.id);
        cb(jobs);
    };
    const titleCase = term
        .split(' ')
        .map(x => { var _a, _b; return ((_b = (_a = x[0]) === null || _a === void 0 ? void 0 : _a.toUpperCase()) !== null && _b !== void 0 ? _b : '') + x.slice(1); })
        .join(' ');
    const upperTerm = term.toUpperCase();
    const limit = 20;
    const unListens = [
        (0, exports.jobsCollection)()
            .where('account.code', '>=', upperTerm)
            .where('account.code', '<=', upperTerm + '\uf8ff')
            .orderBy('account.code', 'asc')
            .orderBy('takeUnixTimestamp', 'desc')
            .limit(limit)
            .onSnapshot(onUpdate),
        (0, exports.jobsCollection)()
            .where('account.name', '>=', titleCase)
            .where('account.name', '<=', titleCase + '\uf8ff')
            .orderBy('account.name', 'asc')
            .orderBy('takeUnixTimestamp', 'desc')
            .limit(limit)
            .onSnapshot(onUpdate),
        (_a = aimsJobNumberQuery(upperTerm)) === null || _a === void 0 ? void 0 : _a.onSnapshot(onUpdate)
    ];
    return () => unListens.map(x => x === null || x === void 0 ? void 0 : x());
}
exports.searchJobs = searchJobs;
const statuses = [
    accounts_models_1.JobStatus.DRAFT,
    accounts_models_1.JobStatus.WAITING_FOR_PHOTOGRAPHERS,
    accounts_models_1.JobStatus.AWAITING_APPROVAL,
    accounts_models_1.JobStatus.UPLOAD_LOCKED,
    accounts_models_1.JobStatus.IN_REVIEW
];
function byBookingIdUploadSiteQuery(id) {
    return (0, exports.jobsCollection)()
        .where('bookingId', '==', id)
        .where('status', 'in', statuses);
}
exports.byBookingIdUploadSiteQuery = byBookingIdUploadSiteQuery;
function getAccountHolderJobs(accountHolderId) {
    return (0, exports.jobsCollection)()
        .where('account.holder.id', '==', accountHolderId)
        .where('status', 'in', statuses);
}
exports.getAccountHolderJobs = getAccountHolderJobs;
let _shootToCloudTypes = [];
async function getMarkets() {
    const marketDocs = await (0, firebase_1.getAppCompat)().firestore().collection('organisation').where('type', '==', 'market').get();
    return marketDocs.docs.map(d => {
        return {
            id: d.id,
            ...d.data()
        };
    });
}
const onlineJobTypes = async () => {
    if (_shootToCloudTypes.length === 0) {
        const [snapShot, markets] = await Promise.all([
            (0, firebase_1.getAppCompat)().firestore().collection('productLines').where('shootToCloudEnabled', '==', true).get(),
            getMarkets()
        ]);
        _shootToCloudTypes = snapShot.docs
            .map(x => x.data())
            .flatMap(p => p.aimsCodes
            .map(code => {
            var _a;
            const market = markets.find(m => m.id === p.marketId);
            return {
                ...code,
                market: ((_a = market === null || market === void 0 ? void 0 : market.imageMarketCode) !== null && _a !== void 0 ? _a : 'S'),
                backToSchoolDelivery: p.backToSchoolDelivery,
                description: `${p.name} (${code.formPrint}) (${code.id})`
            };
        }))
            .sort((a, b) => {
            if (a.description > b.description)
                return 1;
            if (a.description < b.description)
                return -1;
            return 0;
        });
    }
    return _shootToCloudTypes;
};
exports.onlineJobTypes = onlineJobTypes;
let _allJobTypes = [];
const allJobTypes = async () => {
    if (_allJobTypes.length === 0) {
        const [snapShot, markets] = await Promise.all([
            (0, firebase_1.getAppCompat)().firestore().collection('productLines').get(),
            getMarkets()
        ]);
        _allJobTypes = snapShot.docs
            .map(x => x.data())
            .flatMap(p => p.aimsCodes
            .map(code => {
            var _a;
            const market = markets.find(m => m.id === p.marketId);
            return {
                ...code,
                market: ((_a = market === null || market === void 0 ? void 0 : market.imageMarketCode) !== null && _a !== void 0 ? _a : 'S'),
                backToSchoolDelivery: p.backToSchoolDelivery,
                description: `${p.name} (${code.formPrint}) (${code.id})`
            };
        }))
            .sort((a, b) => {
            if (a.description > b.description)
                return 1;
            if (a.description < b.description)
                return -1;
            return 0;
        });
    }
    return _allJobTypes;
};
exports.allJobTypes = allJobTypes;
async function getJobTypeFromCode(jobType) {
    const types = await (0, exports.allJobTypes)();
    return types.find(x => x.id === jobType);
}
exports.getJobTypeFromCode = getJobTypeFromCode;
