"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any, no-use-before-define */
Object.defineProperty(exports, "__esModule", { value: true });
exports.findFile = exports.getFiles = exports.filterFile = exports.filterFiles = exports.isDirectory = void 0;
const isDirectory = (entry) => entry.isDirectory;
exports.isDirectory = isDirectory;
async function getEntries(entry) {
    const more = async () => await new Promise((resolve, reject) => reader.readEntries(resolve, reject));
    const reader = entry.createReader();
    let entries = [];
    let buffer;
    do {
        // eslint-disable-next-line no-await-in-loop
        buffer = await more();
        entries = entries.concat(buffer);
    } while (buffer.length > 0);
    return entries;
}
async function scanSubDirectory(entry) {
    // console.log(`D: ${ entry.fullPath }`)
    return await getEntries(entry);
}
async function scanFile(entry) {
    // console.log(`F: ${ entry.fullPath }`)
    return await new Promise((resolve, reject) => entry.file(resolve, reject));
}
async function scanDirectory(entry, files) {
    if ((0, exports.isDirectory)(entry)) {
        await scanSubDirectory(entry).then(async (x) => await Promise.all(x.map(async (y) => await scanDirectory(y, files))));
        return;
    }
    const file = await scanFile(entry);
    if (filterFile(file)) {
        files.push(file);
    }
}
function filterFiles(files) {
    return files.filter(filterFile);
}
exports.filterFiles = filterFiles;
function filterFile(file) {
    return file.type === 'image/jpeg' || (file.type === 'application/json');
}
exports.filterFile = filterFile;
async function getFiles(entry) {
    const files = [];
    await scanDirectory(entry, files);
    console.log(`Scanned ${files.length} files`);
    return files;
}
exports.getFiles = getFiles;
function findFile(name, files) {
    return files.find(x => x.name === name || x.name.split('.')[0] === name);
}
exports.findFile = findFile;
