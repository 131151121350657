"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAxios = exports.setAxios = void 0;
let _axios;
function setAxios(axios) {
    _axios = axios;
}
exports.setAxios = setAxios;
function getAxios() {
    return _axios;
}
exports.getAxios = getAxios;
