import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=f9a6d584&lang=pug"
import script from "./Login.vue?vue&type=script&lang=ts"
export * from "./Login.vue?vue&type=script&lang=ts"
import style0 from "./Login.vue?vue&type=style&index=0&id=f9a6d584&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QPageContainer,QPage,QBtn});
