"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPrepUrl = exports.setPrepMode = void 0;
let _isProd = true;
function setPrepMode(isProd) {
    _isProd = isProd;
}
exports.setPrepMode = setPrepMode;
const prepUrl = () => _isProd ? 'https://europe-west2-htempest-preproduction-prod.cloudfunctions.net/ImagePrep' : 'https://europe-west2-htempest-preproduction-test.cloudfunctions.net/ImagePrep';
function getPrepUrl(longRef, settings) {
    let str = '';
    for (const key in settings) {
        const val = settings[key];
        if (typeof val === 'undefined')
            continue;
        if (str !== '') {
            str += '&';
        }
        str += key + '=' + encodeURIComponent(val);
    }
    return `${prepUrl()}/${longRef}?${str}`;
}
exports.getPrepUrl = getPrepUrl;
