import { addRequestInterceptor } from '@ht-lib/accounts-api-client'
import * as accountsCommon from '@ht-lib/accounts-common'
import * as activityCollection from '@ht-lib/activity-collection'
import * as imageUtil from '@ht-lib/image-util'
import * as misc from '@ht-vue/misc'
import * as qualityControl from '@ht-vue/quality-control'
import { getDefaultAuth, initializeCompat } from '@ht-web/firebase-adapter'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

export const app = initializeCompat({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
})

activityCollection.setApp(app)
imageUtil.setApp(app)
misc.setApp(app)
qualityControl.setApp(app)
qualityControl.setThumbnailBucket(process.env.VUE_APP_THUMBNAIL_BUCKET)
accountsCommon.setApp(app)

export const auth = getDefaultAuth()

addRequestInterceptor(async (requestConfig) => {
  try {
    if (requestConfig.url.startsWith('/')) {
      const idToken = await auth.currentUser.getIdToken()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      requestConfig.headers.Authorization = 'Bearer ' + idToken
    }
    return requestConfig
  } catch (error) {
    console.error(error)
    return await Promise.reject(error)
  }
}, async (error) => {
  console.error(error)
  return await Promise.reject(error)
}).catch(console.error)
