
import { User } from '@ht-lib/vue-google-signin'
import openURL from 'quasar/src/utils/open-url.js';
import Vue from 'vue'

export default Vue.extend({
  name: 'Default',
  data () {
    return {
      showErrorMessage: false,
      errorMessageText: '',
      leftDrawerOpen: false,
      search: '',
      appsMenu: [
        { icon: 'mdi-webhook', color: 'bookings', text: 'Bookings', link: 'https://cortex.tempestapps.io' },
        {
          icon: 'mdi-chart-areaspline',
          color: 'sales-support',
          text: 'Sales',
          link: 'https://salessupport.tempestapps.io'
        },
        { icon: 'mdi-cloud-upload', color: 'upload', text: 'upload', link: 'https://upload.tempestapps.io' },
        { icon: 'mdi-image-search', color: 'qc', text: 'QC', link: 'https://images.tempestapps.io' }
      ]
    }
  },
  computed: {
    user (): User {
      return this.$auth.user || {
        profile: null,
        uid: '',
        email: '',
        name: '',
        picture: '../assets/sad.svg'
      }
    }
  },
  methods: {
    gotoUrl (link: string): void {
      openURL(link)
    }
  }
})
