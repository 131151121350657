"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadBookingJobJson = exports.updateBookingStudioJob = exports.bookingStudioJobsCollection = void 0;
const firebase_1 = require("../firebase");
const BUCKET = process.env.VUE_APP_JOB_JSON_BUCKET;
const bookingStudioJobsCollection = () => (0, firebase_1.getAppCompat)().firestore().collection('bookingStudioJobs');
exports.bookingStudioJobsCollection = bookingStudioJobsCollection;
async function updateBookingStudioJob(studioJobId, update) {
    return await (0, exports.bookingStudioJobsCollection)().doc(studioJobId).set(update, { merge: true });
}
exports.updateBookingStudioJob = updateBookingStudioJob;
async function uploadBookingJobJson(studioJobId, jobJson) {
    await (0, firebase_1.getAppCompat)().storage().refFromURL(`gs://${BUCKET}/${studioJobId}`).put(jobJson);
}
exports.uploadBookingJobJson = uploadBookingJobJson;
