"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadSingleImage = exports.UploadManager = void 0;
const firebase_adapter_1 = require("@ht-web/firebase-adapter");
const date_fns_1 = require("date-fns");
const hash_wasm_1 = require("hash-wasm");
const BATCH_SIZE = 5;
class UploadManager {
    constructor(options) {
        this.options = options;
        this.queue = [];
        this._currentJob = null;
    }
    get currentJob() {
        return this._currentJob;
    }
    get isUploading() {
        return this._currentJob !== null;
    }
    get totalUploads() {
        return this.queue.reduce((accumulator, job) => accumulator + job.images.length, this.currentJobImageCount);
    }
    get completedUploadsCount() {
        return this.queue.reduce((accumulator, job) => accumulator + job.images.filter(x => x.uploaded).length, this.currentJobUploaded);
    }
    get currentJobImageCount() {
        var _a, _b;
        return (_b = (_a = this._currentJob) === null || _a === void 0 ? void 0 : _a.images.length) !== null && _b !== void 0 ? _b : 0;
    }
    get currentJobUploaded() {
        var _a, _b;
        return (_b = (_a = this._currentJob) === null || _a === void 0 ? void 0 : _a.images.filter(x => x.uploaded).length) !== null && _b !== void 0 ? _b : 0;
    }
    async upload(img, count = 0) {
        var _a, _b, _c, _d;
        if (this._currentJob == null) {
            console.error('No job found but trying to upload Image: ', img);
            return;
        }
        try {
            await (0, exports.uploadSingleImage)(img);
            await ((_b = (_a = this.options).onImageUploaded) === null || _b === void 0 ? void 0 : _b.call(_a, this._currentJob, img));
        }
        catch (e) {
            console.error('Error uploading image: ', img.longRef, e);
            if (count < 5) {
                await new Promise((resolve) => setTimeout(resolve, (Math.random() * 500) + 200));
                console.log('Retrying Image:', img.longRef, 'retry count: ', count);
                return await this.upload(img, count + 1);
            }
            const failMesasge = `Failed to upload image: ${img.longRef} after: ${count} retries`;
            console.error(failMesasge);
            await ((_d = (_c = this.options).onImageError) === null || _d === void 0 ? void 0 : _d.call(_c, this._currentJob, img));
            if (this.options.throwError === true) {
                throw Error(failMesasge);
            }
        }
    }
    addUploadJob(job) {
        this.queue.push({ ...job, startTime: (0, date_fns_1.getUnixTime)(new Date()) });
        if (this._currentJob == null) {
            this.process()
                .then(() => { console.log('Image upload processing complete'); })
                .catch(e => { console.error('Image uploadi processing error', e); });
        }
    }
    async nextJob() {
        var _a, _b, _c;
        this._currentJob = (_a = this.queue.pop()) !== null && _a !== void 0 ? _a : null;
        if (this._currentJob === null)
            return;
        await ((_c = (_b = this.options).onNextJob) === null || _c === void 0 ? void 0 : _c.call(_b, this._currentJob));
    }
    async process() {
        var _a, _b, _c, _d;
        await this.nextJob();
        while (this._currentJob !== null) {
            const batch = [];
            for (const image of this._currentJob.images) {
                const task = this.upload(image)
                    .finally(() => {
                    const index = batch.indexOf(task);
                    if (index !== -1) {
                        batch.splice(index, 1);
                    }
                });
                if (batch.push(task) >= BATCH_SIZE) {
                    await Promise.race(batch);
                }
            }
            await Promise.all(batch);
            (_b = (_a = this._currentJob) === null || _a === void 0 ? void 0 : _a.onFinish) === null || _b === void 0 ? void 0 : _b.call(_a).catch(e => { console.error('Error execting onFinish', e); });
            (_d = (_c = this.options).onJobFinish) === null || _d === void 0 ? void 0 : _d.call(_c, this._currentJob).catch(e => { console.error('Error execting onJobFinish', e); });
            await this.nextJob();
        }
    }
}
exports.UploadManager = UploadManager;
const uploadSingleImage = async (img) => {
    const bucket = (0, firebase_adapter_1.getDefaultBucket)();
    if (await imageAlreadyUploaded(img, bucket)) {
        console.log(`${img.longRef} - MD5 matches, skipping upload`);
        await firebase_adapter_1.Image.byId(img.longRef).setUploaded().catch(e => { console.error(e); });
    }
    else {
        await bucket.upload(img.longRef, img.file);
    }
    img.uploaded = true;
};
exports.uploadSingleImage = uploadSingleImage;
const imageAlreadyUploaded = async (img, bucket) => {
    try {
        const metadata = await bucket.getMetadata(img.longRef); // getMetadata will throw if object does not exist
        if (metadata.md5Hash == null) {
            return false;
        }
        const objectHash = Array.from(atob(metadata.md5Hash)).map(x => x.charCodeAt(0).toString(16)).join('');
        const md5Hash = await (0, hash_wasm_1.md5)(new Uint8Array(await img.file.arrayBuffer()));
        return objectHash === md5Hash;
    }
    catch { }
    return false;
};
