import { JobSnap, updateJob } from '@ht-lib/accounts-common'
import { JobStatus } from '@ht-lib/accounts-models'
import { ControlConfig, route as QCRoute } from '@ht-vue/quality-control'
import { Route } from 'vue-router'
import Default from '../layouts/Default.vue'

const headerHeight = 50
export const height = headerHeight

const jobConfig: ControlConfig = {
  finish: {
    route: '/',
    cb: async (job?: JobSnap) => {
      console.log('QC Finished for job: ', job?.data())
      if (job != null) {
        await updateJob(job.id, {
          hasBeenQCed: true,
          status: JobStatus.COMPLETE
        })
      }
    }
  },
  save: {
    route: '/',
    cb: (job?: JobSnap) => {
      console.log('QC Saved for job:', job?.data())
    }
  },
  inLab: true,
  showFinishSave: true,
  showPrintWorksheet: true,
  height: headerHeight
}

export default [
  {
    path: '/',
    component: Default,
    children: [
      {
        path: '',
        name: 'Review',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/Index.vue')
      },
      {
        path: 'nexus/:maybeAimsJobNumber',
        name: 'Nexus',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/Nexus.vue'),
        props: (route: Route): unknown => ({ maybeAimsJobNumber: route.params.maybeAimsJobNumber })
      },
      QCRoute(jobConfig)
    ]
  },
  {
    path: '/401',
    component: async () => await import(/* webpackPrefetch: true */ '@/pages/Error401.vue')
  },
  { // Always leave this as last one
    path: '*',
    component: async () => await import(/* webpackPrefetch: true */ '@/pages/Error404.vue')
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about", webpackPrefetch: true */ '../views/About.vue')
  // }
]
