"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateAccount = exports.getAccount = exports.getAccountRef = exports.accountsCollection = void 0;
const firebase_1 = require("../firebase");
const accountsCollection = () => (0, firebase_1.getAppCompat)().firestore().collection('accounts');
exports.accountsCollection = accountsCollection;
function getAccountRef(id) {
    return (0, exports.accountsCollection)().doc(id);
}
exports.getAccountRef = getAccountRef;
async function getAccount(accountId) {
    return (await (0, exports.accountsCollection)().doc(accountId).get()).data();
}
exports.getAccount = getAccount;
async function updateAccount(accountId, update) {
    return await (0, exports.accountsCollection)().doc(accountId).set(update, { merge: true });
}
exports.updateAccount = updateAccount;
