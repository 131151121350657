"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addActivity = exports.setApp = void 0;
const date_fns_1 = require("date-fns");
const firebase_1 = require("./firebase");
__exportStar(require("@ht-lib/accounts-models/lib/activity"), exports);
var firebase_2 = require("./firebase");
Object.defineProperty(exports, "setApp", { enumerable: true, get: function () { return firebase_2.setApp; } });
const converter = {
    fromFirestore: (snapshot) => {
        return snapshot.data();
    },
    toFirestore: (activityEntry) => {
        return activityEntry;
    }
};
let _coll = null;
function collection() {
    if (_coll === null) {
        _coll = (0, firebase_1.getAppCompat)().firestore().collection('activity').withConverter(converter);
    }
    return _coll;
}
async function addActivity(activity) {
    const badValues = Object
        .entries(activity)
        .filter(([key, value]) => {
        if (value == null) {
            console.error('Found nullish value when adding to activity log. Value Key: ', key);
            return true;
        }
        return false;
    });
    if (badValues.length > 0) {
        return;
    }
    const update = {
        ...activity,
        timestamp: (0, date_fns_1.getUnixTime)(new Date())
    };
    await collection().add(update);
}
exports.addActivity = addActivity;
