import * as imageUtil from '@ht-lib/image-util'
import VueGoogleSignin from '@ht-lib/vue-google-signin'
import * as qualityControl from '@ht-vue/quality-control'
import axios from 'axios'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import './axios'
import { auth } from './firebase'
import './quasar'
import router from './router'

qualityControl.enableFeatures('SPLIT_JOB')
qualityControl.setAxios(axios)
imageUtil.setAxios(axios)

Vue.use(Vuelidate)
Vue.use(new VueGoogleSignin(), {
  router,
  appName: 'Job Manager',
  firebaseAuth: auth,
  defaultRouteName: 'Review'
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
