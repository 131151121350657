"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.batchUpdateImages = exports.batchMergeImages = exports.getImagesByJobId = exports.imagesByAimsJobSnap = exports.imagesByAimsJobNumber = exports.imagesRefsSnap = exports.getImageByRef = exports.imagesLongRefsSnap = exports.childProtectionImagesQuery = exports.getImage = void 0;
const firebase_adapter_1 = require("@ht-web/firebase-adapter");
async function getImage(longRef) {
    return await firebase_adapter_1.Image.byId(longRef).single();
}
exports.getImage = getImage;
async function childProtectionImagesQuery(jobId) {
    return await firebase_adapter_1.Image.byJobIdWhereChildProtection(jobId).multiple();
}
exports.childProtectionImagesQuery = childProtectionImagesQuery;
function imagesLongRefsSnap(images, onImages) {
    const cache = new Map();
    const unsubscribes = images.map(x => firebase_adapter_1.Image.byId(x).listenWithCache(cache, () => onImages([...cache.values()], false)));
    return () => unsubscribes.forEach(x => x());
}
exports.imagesLongRefsSnap = imagesLongRefsSnap;
async function getImageByRef(market, ref) {
    return await firebase_adapter_1.Image.byRef(market, ref).single();
}
exports.getImageByRef = getImageByRef;
function imagesRefsSnap(images, onImages) {
    const listenByRef = (market, ref) => firebase_adapter_1.Image.byRef(market, ref).listen(images => onImages(images, true));
    const unsubscribes = images.map(x => listenByRef(x.market, x.ref));
    return () => unsubscribes.forEach(x => x());
}
exports.imagesRefsSnap = imagesRefsSnap;
async function imagesByAimsJobNumber(accountCode, jobCode, onlyFlagged = false) {
    return await firebase_adapter_1.Image.byAimsJob(accountCode, jobCode, onlyFlagged).multiple();
}
exports.imagesByAimsJobNumber = imagesByAimsJobNumber;
function imagesByAimsJobSnap(accountCode, jobCode, onImages, onlyFlagged = false) {
    return firebase_adapter_1.Image
        .byAimsJob(accountCode, jobCode, onlyFlagged)
        .listen(images => onImages(images, false));
}
exports.imagesByAimsJobSnap = imagesByAimsJobSnap;
async function getImagesByJobId(jobId) {
    return await firebase_adapter_1.Image.byJobId(jobId).multiple();
}
exports.getImagesByJobId = getImagesByJobId;
async function batchMergeImages(images) {
    return await firebase_adapter_1.Image.batchSetFromData(images);
}
exports.batchMergeImages = batchMergeImages;
async function batchUpdateImages(images) {
    return await firebase_adapter_1.Image.batchUpdateFromData(images);
}
exports.batchUpdateImages = batchUpdateImages;
