"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImageNewPreferredValue = exports.ensureThereIsAPreferredImageAmongRelatedImages = exports.updateClass = exports.updatePupilId = exports.rejectImage = exports.acceptImage = exports.getImage = exports.imageChangeData = void 0;
const accounts_models_1 = require("@ht-lib/accounts-models");
const imageChangeData = (image) => ({
    imageGroupName: image.className,
    isGroupShot: isGroupShot(image),
    pupilId: image.pupilId,
    excludeLongref: image.longRef
});
exports.imageChangeData = imageChangeData;
const isGroupShot = (image) => image.shotTypeId === accounts_models_1.ShotType.IndoorGroup || image.shotTypeId === accounts_models_1.ShotType.OutdoorGroup;
const isAcceptedGroupShot = (image) => image.status !== accounts_models_1.ImageStatus.REJECTED &&
    (image.shotTypeId === accounts_models_1.ShotType.IndoorGroup || image.shotTypeId === accounts_models_1.ShotType.OutdoorGroup);
const isAcceptedPortraitShotWithSamePupilId = (image, pupilId) => image.status !== accounts_models_1.ImageStatus.REJECTED &&
    image.shotTypeId === accounts_models_1.ShotType.PortraitMode &&
    image.pupilId === pupilId;
function getImage(images, longref) {
    const image = images.find(i => i.longRef === longref);
    if (image == null) {
        throw new Error('No image for longref ' + longref);
    }
    return image;
}
exports.getImage = getImage;
function acceptImage(images, longref, status) {
    const image = getImage(images, longref);
    if (image.status !== accounts_models_1.ImageStatus.REJECTED || status === accounts_models_1.ImageStatus.REJECTED) {
        return false;
    }
    const change = (0, exports.imageChangeData)(image);
    image.status = status;
    image.isPreferred = getImageNewPreferredValue(images, change);
    return true;
}
exports.acceptImage = acceptImage;
function rejectImage(images, longref) {
    const image = getImage(images, longref);
    const change = (0, exports.imageChangeData)(image);
    image.status = accounts_models_1.ImageStatus.REJECTED;
    image.isPreferred = false;
    ensureThereIsAPreferredImageAmongRelatedImages(images, change);
    return true;
}
exports.rejectImage = rejectImage;
function updatePupilId(images, longref, newPupilId, newPupilName) {
    const image = getImage(images, longref);
    const change = (0, exports.imageChangeData)(image);
    image.pupilId = newPupilId;
    if (newPupilName != null) {
        image.pupilName = newPupilName;
    }
    ensureThereIsAPreferredImageAmongRelatedImages(images, change);
    change.pupilId = newPupilId;
    image.isPreferred = getImageNewPreferredValue(images, change);
    return true;
}
exports.updatePupilId = updatePupilId;
function updateClass(images, longref, newClassName) {
    const image = getImage(images, longref);
    const change = (0, exports.imageChangeData)(image);
    image.className = newClassName;
    ensureThereIsAPreferredImageAmongRelatedImages(images, change);
    change.imageGroupName = newClassName;
    image.isPreferred = getImageNewPreferredValue(images, change);
    return true;
}
exports.updateClass = updateClass;
function ensureThereIsAPreferredImageAmongRelatedImages(images, imageChange) {
    const relatedImages = getRelatedImages(images, imageChange);
    if (relatedImages.length > 0 && !relatedImages.some(i => i.isPreferred)) {
        relatedImages[0].isPreferred = true;
        return { longref: relatedImages[0].longRef, newPreferredStatus: true };
    }
    return null;
}
exports.ensureThereIsAPreferredImageAmongRelatedImages = ensureThereIsAPreferredImageAmongRelatedImages;
/**
 * For a given image, returns true if this image should be a preferred image and false if it should not
 * Uses `getRelatedImages()` to see if any related images are preferred, and returns true if not
 *
 * Run this after accepting an image or updating the pupil id or class name
 */
function getImageNewPreferredValue(images, imageChange) {
    const relatedImages = getRelatedImages(images, imageChange);
    const relatedImagesNeedsAPreferredImage = relatedImages.length === 0 || !relatedImages.some(i => i.isPreferred);
    return relatedImagesNeedsAPreferredImage;
}
exports.getImageNewPreferredValue = getImageNewPreferredValue;
/**
 * For a given image, returns any images which are "related" to this image.
 * For a group image, returns all other accepted group images in the same class or docket.
 * For a data-linked portrait image, returns all other accepted portrait images on this booking / aims job with a matching pupilId.
 * In all other circumstances, an empty array is returned.
 */
function getRelatedImages(images, imageChange) {
    const { imageGroupName, isGroupShot, pupilId, excludeLongref } = imageChange;
    if (imageGroupName == null) {
        return [];
    }
    if (isGroupShot) {
        return Object.values(images).filter(image => image.className === imageGroupName &&
            image.longRef !== excludeLongref &&
            isAcceptedGroupShot(image));
    }
    else if (pupilId != null && pupilId.length > 0) {
        return Object.values(images).filter(image => image.longRef !== excludeLongref &&
            isAcceptedPortraitShotWithSamePupilId(image, pupilId));
    }
    else {
        return [];
    }
}
