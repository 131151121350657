"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTransactionHistory = exports.refundTransaction = exports.searchTransactions = void 0;
const axios_1 = require("./axios");
const baseUrl = '';
async function searchTransactions(searchParams) {
    const resp = await (0, axios_1.axios)({
        method: 'post',
        url: `${baseUrl}/refund-search`,
        data: searchParams
    });
    return resp.data;
}
exports.searchTransactions = searchTransactions;
async function refundTransaction(request) {
    const resp = await (0, axios_1.axios)({
        method: 'post',
        url: `${baseUrl}/refund`,
        data: request
    });
    return resp.data;
}
exports.refundTransaction = refundTransaction;
async function getTransactionHistory(paymentId) {
    const resp = await (0, axios_1.axios)({
        method: 'get',
        url: `${baseUrl}/transaction-history/${paymentId}`
    });
    return resp.data;
}
exports.getTransactionHistory = getTransactionHistory;
