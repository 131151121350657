"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toJobPhotographerMap = exports.getAccountsQuery = exports.getAccountBookings = exports.addLabUploaderToBooking = exports.getLabUploaderBookings = exports.getUserBookings = exports.updateBookingFromRef = exports.updateBooking = exports.getBooking = exports.getBookingRef = void 0;
const accounts_models_1 = require("@ht-lib/accounts-models");
const date_fns_1 = require("date-fns");
const lodash_1 = require("lodash");
const firebase_1 = require("../firebase");
const bookingConverter = {
    fromFirestore(snapshot) {
        return snapshot.data();
    },
    toFirestore(modelObject) {
        const insert = (0, lodash_1.clone)(modelObject);
        const removeIfUndefined = (u) => {
            if (typeof insert[u] === 'undefined') {
                delete insert[u];
            }
        };
        removeIfUndefined('cancelReason');
        removeIfUndefined('cancelNotes');
        removeIfUndefined('deliveryContact');
        removeIfUndefined('deliveryAddress');
        removeIfUndefined('datalinkingContact');
        removeIfUndefined('options');
        return { ...insert };
    }
};
const userBookings = (userId) => (0, firebase_1.getAppCompat)().firestore()
    .collection(`userProfiles/${userId}/diary`)
    .withConverter(bookingConverter);
const globalBookings = () => (0, firebase_1.getAppCompat)().firestore()
    .collection('bookings')
    .withConverter(bookingConverter);
function getBookingRef(id) {
    return globalBookings().doc(id);
}
exports.getBookingRef = getBookingRef;
async function getBooking(id) {
    return await getBookingRef(id).get();
}
exports.getBooking = getBooking;
async function updateBooking(id, update) {
    return await globalBookings().doc(id).set(update, { merge: true });
}
exports.updateBooking = updateBooking;
async function updateBookingFromRef(ref, update) {
    return await ref.set(update, { merge: true });
}
exports.updateBookingFromRef = updateBookingFromRef;
const STC_START = (0, date_fns_1.sub)(new Date(), { months: 6 });
function getUserBookings(userId) {
    return getUploadSiteBookings(userBookings(userId));
}
exports.getUserBookings = getUserBookings;
function getLabUploaderBookings(userId) {
    return getUploadSiteBookings(globalBookings().where('labUploaders', 'array-contains', userId));
}
exports.getLabUploaderBookings = getLabUploaderBookings;
async function addLabUploaderToBooking(ref, labUploaderId) {
    const update = {
        labUploaders: firebase_1.FieldValue.arrayUnion(labUploaderId)
    };
    await ref.update(update);
}
exports.addLabUploaderToBooking = addLabUploaderToBooking;
function getAccountBookings(accountCode) {
    return getUploadSiteBookings(globalBookings().where('accountCode', '==', accountCode));
}
exports.getAccountBookings = getAccountBookings;
function getAccountsQuery(accountHolderId) {
    return getUploadSiteBookings(globalBookings().where('accountHolderContactInfo.id', '==', accountHolderId));
}
exports.getAccountsQuery = getAccountsQuery;
function getUploadSiteBookings(baseCollection) {
    return baseCollection.where('status', '==', accounts_models_1.BookingStatus.CREATED)
        .where('arrivalUnixTimestamp', '<=', (0, date_fns_1.getUnixTime)((0, date_fns_1.endOfToday)()))
        .where('arrivalUnixTimestamp', '>=', (0, date_fns_1.getUnixTime)(STC_START))
        .orderBy('arrivalUnixTimestamp', 'desc');
}
function toJobPhotographerMap(photographersMap) {
    var _a, _b;
    const jobPhotographersMap = {};
    for (const photographerId in photographersMap) {
        const photographer = photographersMap[photographerId];
        jobPhotographersMap[photographerId] = {
            commission: null,
            id: photographer.id,
            name: photographer.name,
            email: (_b = (_a = photographer.contact) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : 'none',
            imageCount: 0,
            code: photographer.photographerId,
            status: accounts_models_1.PhotographerJobStatus.AWAITING,
            role: accounts_models_1.PhotographerRole.PHOTOGRAPHER
        };
    }
    return jobPhotographersMap;
}
exports.toJobPhotographerMap = toJobPhotographerMap;
