"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePhotographerStatus = exports.updatePhotographerStatusById = exports.fetchUserProfile = exports.getUserProfileId = exports.resetUserProfileId = exports.formatDate = exports.capitalizeFirstLetter = exports.formatContactToAccountContact = exports.formatContactName = void 0;
const firebase_1 = require("./firebase");
const job_1 = require("./models/job");
function formatContactName(contact) {
    const out = [];
    if (contact.title)
        out.push(capitalizeFirstLetter(contact.title));
    if (contact.forename)
        out.push(capitalizeFirstLetter(contact.forename));
    if (contact.surname)
        out.push(capitalizeFirstLetter(contact.surname));
    return out.join(' ');
}
exports.formatContactName = formatContactName;
function formatContactToAccountContact(contact) {
    return {
        id: contact.id,
        email: contact.email || null,
        name: contact.fullName || formatContactName(contact) || null,
        phone: contact.mobile || contact.telephone || null,
        role: contact.role || null
    };
}
exports.formatContactToAccountContact = formatContactToAccountContact;
function capitalizeFirstLetter(word) {
    return word ? word.toLowerCase().charAt(0).toUpperCase() + word.slice(1) : word;
}
exports.capitalizeFirstLetter = capitalizeFirstLetter;
function formatDate(date) {
    return date instanceof Date ? date.toDateString() : new Date(date).toDateString();
}
exports.formatDate = formatDate;
const USERPROFILES = 'userProfiles';
let _userProfileId = null;
const resetUserProfileId = () => { _userProfileId = null; };
exports.resetUserProfileId = resetUserProfileId;
const getUserProfileId = async () => {
    return _userProfileId !== null && _userProfileId !== void 0 ? _userProfileId : (_userProfileId = await fetchUserProfileId());
};
exports.getUserProfileId = getUserProfileId;
const fetchUserProfileId = async () => {
    const profile = await (0, exports.fetchUserProfile)();
    return profile == null ? (0, firebase_1.getAppCompat)().firestore().collection(USERPROFILES).doc().id : profile.id;
};
const fetchUserProfile = async () => {
    const user = (0, firebase_1.getAppCompat)().auth().currentUser;
    if ((user === null || user === void 0 ? void 0 : user.email) == null) {
        return null;
    }
    const profiles = await (0, firebase_1.getAppCompat)().firestore()
        .collection(USERPROFILES)
        .where('mail', '==', user.email.toLowerCase())
        .get();
    return profiles.empty ? null : profiles.docs[0];
};
exports.fetchUserProfile = fetchUserProfile;
const updatePhotographerStatusById = async (jobId, status) => {
    const job = await (0, job_1.getJob)(jobId);
    return await (0, exports.updatePhotographerStatus)(job, status);
};
exports.updatePhotographerStatusById = updatePhotographerStatusById;
const updatePhotographerStatus = async (job, status, userId = null) => {
    if (userId == null) {
        userId = await (0, exports.getUserProfileId)();
    }
    if (userId === null) {
        throw Error('User not logged in');
    }
    return await (0, job_1.updateJob)(job.id, {
        photographersMap: {
            [userId]: {
                status
            }
        }
    });
};
exports.updatePhotographerStatus = updatePhotographerStatus;
